import axios from "axios";
import {
  Badge,
  Button,
  Checkbox,
  Label,
  Modal,
  Select,
  Spinner,
  TextInput,
  Tooltip,
} from "flowbite-react";
import { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const BonusArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
`;

const LabelWithIcon = styled.div`
  display: flex;
  flex-direction: row;
`;

const HorizontalContainer = styled.div`
  display: flex;
  align-items: center; // This ensures vertical alignment in the center
  gap: 16px; // This adds space between the elements

  > :first-child {
    width: 80%;
  }
`;

const BankInfo = styled.div`
  display: flex;
  gap: 8px;
`;

export const BASE_URL = "https://ttkd.vnptdanang.vn/shopee/";

const GetLink = ({ openModal, setOpenModal }: any) => {
  const [loading, setLoading] = useState(false);
  const [affiliateLink, setAffiliateLink] = useState("");
  const [bonus, setBonus] = useState("");
  const [link, setLink] = useState("");
  const [phone, setPhone] = useState("");
  const [bankInfo, setBankInfo] = useState(false);
  const [wallet, setWallet] = useState("Momo");
  // Add state for bank name, account number, and account name
  const [bankName, setBankName] = useState("Vietcombank");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [linkError, setLinkError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    const wallet = localStorage.getItem("wallet");
    const storedPhone = localStorage.getItem("phone");
    const storedBankName = localStorage.getItem("bankName");
    const storedAccountNumber = localStorage.getItem("accountNumber");
    const storedAccountName = localStorage.getItem("accountName");

    if (storedPhone) {
      setPhone(storedPhone);
      setWallet(wallet || "Momo");
    }
    if (storedBankName && storedAccountNumber && storedAccountName) {
      setBankInfo(true);
      setBankName(storedBankName);
      setAccountNumber(storedAccountNumber);
      setAccountName(storedAccountName);
    }
  }, []);

  const handleGetLink = () => {
    if (!link) {
      setLinkError("Error");
      return;
    }
    if (!phone) {
      setPhoneError("Error");
      return;
    }
    setLoading(true);
    const bank = `${bankName}_${accountNumber}_${accountName.replaceAll(
      " ",
      ""
    )}`;
    const phoneData = `${phone}_${wallet}`;
    let url = `${BASE_URL}/getlink.php?shortURL=${link}&phone=${phoneData}`;
    if (bankInfo) {
      url = url.concat(`&bank=${bank}`);
    }

    axios
      .get(url)
      .then((response) => {
        setAffiliateLink(response.data.graphql[0].productOfferLink);
        setBonus(response.data.product);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });

    localStorage.setItem("phone", phone);
    localStorage.setItem("wallet", wallet);
    if (bankInfo) {
      localStorage.setItem("bankName", bankName);
      localStorage.setItem("accountNumber", accountNumber);
      localStorage.setItem("accountName", accountName);
    }
  };

  return (
    <>
      <Modal
        show={openModal}
        onClose={() => {
          setAffiliateLink("");
          setBonus("");
          setLink("");
          setOpenModal(false);
        }}
      >
        <Modal.Header>Tạo link tiếp thị liên kết</Modal.Header>
        <Modal.Body>
          <Container>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="link" value="Link sản phẩm" />
              </div>
              <TextInput
                type="text"
                onChange={(e) => {
                  setLinkError("");
                  setLink(e.target.value);
                }}
                placeholder="Dán link sản phẩm vào đây"
                required
                value={link}
                color={linkError ? "failure" : "gray"}
              />
            </div>
            <div>
              <LabelWithIcon className="mb-2 block">
                <Label htmlFor="phone" value="Số điện thoại" />
                <Tooltip content="Thanh toán bằng ví điện tử theo số điện thoại">
                  <img src="/info-icon-32.png" className="ml-2 h-4" />
                </Tooltip>
              </LabelWithIcon>
              <HorizontalContainer>
                <TextInput
                  type="text"
                  value={phone}
                  required
                  onChange={(e) => {
                    setPhoneError("");
                    setPhone(e.target.value)
                  }}
                  placeholder={`Số điện thoại ví ${wallet}`}
                  color={phoneError ? "failure" : "gray"}
                />
                <Select
                  id="wallet"
                  required
                  value={wallet}
                  onChange={(e) => {
                    const selectedWallet = (e.target as HTMLSelectElement)
                      .value;
                    setWallet(selectedWallet);
                  }}
                >
                  <option>Momo</option>
                  <option>Zalopay</option>
                  <option>VNPTMoney</option>
                  <option>ShopeePay</option>
                </Select>
              </HorizontalContainer>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="bankInfo"
                checked={bankInfo}
                onChange={() => setBankInfo(!bankInfo)}
              />
              <Label htmlFor="bankInfo">
                Không có ví điện tử? Nhập tài khoản ngân hàng:
              </Label>
            </div>
            {bankInfo && (
              <BankInfo>
                <Select
                  id="bank"
                  required
                  value={bankName}
                  onChange={(e) => {
                    const selected = (e.target as HTMLSelectElement).value;
                    setBankName(selected);
                  }}
                >
                  <option>Vietcombank</option>
                  <option>Techcombank</option>
                  <option>BIDV</option>
                  <option>ACB</option>
                  <option>MB</option>
                  <option>VPBank</option>
                  <option>TPBank</option>
                  <option>Sacombank</option>
                  <option>VIB</option>
                  <option>Eximbank</option>
                  <option>OceanBank</option>
                  <option>HSBC Vietnam</option>
                  <option>Agribank</option>
                  <option>SCB Vietnam</option>
                  <option>SHB</option>
                </Select>
                <TextInput
                  type="text"
                  placeholder="Số tài khoản"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
                <TextInput
                  type="text"
                  placeholder="Tên tài khoản"
                  value={accountName}
                  onChange={(e) => setAccountName(e.target.value)}
                />
              </BankInfo>
            )}

            <Button
              onClick={() => {
                handleGetLink();
              }}
            >
              {loading && <Spinner aria-label="Default status example" />}Lấy
              link
            </Button>

            {affiliateLink && (
              <BonusArea>
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="affiliateLink"
                      value="Link tiếp thị liên kết"
                    />
                  </div>
                  <Badge size="sm" href={affiliateLink}>
                    {affiliateLink}
                  </Badge>
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="bonus" value="Hoa hồng" />
                  </div>
                  <Badge color="purple">{bonus} VND</Badge>
                </div>
              </BonusArea>
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GetLink;
